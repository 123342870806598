import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(private cookieService: CookieService) {}

  // Set Cookie mit Optionen für Ablaufdatum und Sicherheit
  setCookie(name: string, value: string, days: number = 1): void {
    const expires = days * 24 * 60 * 60 ; // In Millisekunden
    this.cookieService.set(name, value, expires, '/', '', true, 'Lax');
  }

  // Hole Cookie-Wert
  getCookie(name: string): string {
    return this.cookieService.get(name);
  }

  // Lösche Cookie
  deleteCookie(name: string): void {
    this.cookieService.delete(name, '/');
  }
}
