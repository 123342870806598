import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';
@Injectable({
  providedIn: 'root',
})
export class CartService {
  private items: any[] = JSON.parse(
    this.storageService.getCookie('cartItems') || '[]'
  );
  private favorites: any[] = JSON.parse(
    this.storageService.getCookie('favoriteItems') || '[]'
  );
  private discount: number = parseInt(
    this.storageService.getCookie('discount') || '0'
  );
  private code: string = this.storageService.getCookie('code') || '';
  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private storageService: StorageService
  ) {}

  addToCart(product: any) {
    this.items.push({ ...product, quantitiy: 1 });
    this.storageService.setCookie('cartItems', JSON.stringify(this.items));
  }
  addToFavorites(product: any) {
    this.favorites.push(product);
    this.storageService.setCookie(
      'favoriteItems',
      JSON.stringify(this.favorites)
    );
  }
  getShipping() {
    let total = this.getAccurateSubotal() * (1 - this.discount / 100);
    if (total == 0) {
      return 0;
    } else if (total < 40 && this.items.length <= 10) {
      return 2;
    } else if (total < 100) {
      return 5.49;
    } else {
      return 0;
    }
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  deleteFavorite(item: any) {
    this.favorites = this.favorites.filter((i) => i.coin_id !== item.coin_id);
    this.storageService.setCookie(
      'favoriteItems',
      JSON.stringify(this.favorites)
    );
  }
  getDiscount() {
    return this.discount;
  }

  delete(item: any) {
    this.items = this.items.filter((i) => i.coin_id !== item.coin_id);
    this.storageService.setCookie('cartItems', JSON.stringify(this.items));
  }

  clearCart() {
    this.storageService.deleteCookie('cartItems');
    this.storageService.deleteCookie('discount');
  }

  incrementQuantitiy(id: number) {
    let item = this.items.find((i) => i.coin_id === id);
    if (item) {
      item.quantitiy++;
      this.storageService.setCookie('cartItems', JSON.stringify(this.items));
    }
  }
  decrementQuantitiy(id: number) {
    let item = this.items.find((i) => i.coin_id === id);
    if (item) {
      if (item.quantitiy - 1 > 0) {
        item.quantitiy--;
      } else {
        this.delete(item);
      }
      this.storageService.setCookie('cartItems', JSON.stringify(this.items));
    }
  }
  getItems() {
    return JSON.parse(this.storageService.getCookie('cartItems') || '[]');
  }
  getFavorites() {
    return JSON.parse(this.storageService.getCookie('favoriteItems') || '[]');
  }

  getTotal() {
    if (this.getAccurateSubotal() > 0) {
      return parseFloat(
        (
          this.getAccurateSubotal() * (1 - this.discount / 100) +
          this.getShipping()
        ).toFixed(2)
      );
    } else {
      return 0;
    }
  }
  calculateTotal() {
    let url = 'https://stockapi.myftp.org/api/v2/shop/total/' + this.code;
    return this.http.post<string>(url, this.items);
  }
  getCoinTotal(id: number) {
    let item = this.items.find((i) => i.coin_id === id);
    return item.vk_price * item.quantitiy;
  }
  getAmmount() {
    return this.items.reduce((acc, item) => {
      return acc + item.quantitiy;
    }, 0);
  }
  getSubotal() {
    return this.items.reduce((acc, item) => {
      return acc + item.vk_price * item.quantitiy;
    }, 0);
  }
  getAccurateSubotal() {
    return this.items.reduce((acc, item) => {
      return (
        acc +
        (item.discount > 0 ? item.discount : item.vk_price) * item.quantitiy
      );
    }, 0);
  }
}
